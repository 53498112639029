<template>
  <RoutePlanElement
    v-if="selectedPlan"
    :route-plan="selectedPlan"
    @edit="edit"
    @back="success"
  ></RoutePlanElement>
</template>

<script>
import Vue from "vue";
import RoutePlanElement from "@/components/routePlan/RoutePlanElement";

export default {
  name: "RoutePlanElementShowView",
  components: {RoutePlanElement},
  data() {
    return {
      routeId:0,
      selectedPlan: null,
    };
  },
  async mounted() {
    this.routeId = this.$route.params.id;
		this.selectedPlan = await this.$rest.getRoutePlanById(this.routeId);
  },
  methods:{
    success(){
      Vue.set(Vue.prototype, '$rightMenu', []);
      this.$router.push({path: '/route-planning'});
    },
    edit() {
      this.$router.push({path: '/route-planning-element/' + this.selectedPlan.id});
    },
  }
}
</script>

<style scoped>

</style>
