<template>
  <div class="container-fluid mt-2 ml-2">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-12 text-center">
            <h4 class="pb-0">
              {{ routePlan.name }}
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-1 ml-2 mb-2" style="border-bottom:1px solid #444444">
            <a v-on:click="back" href="javascript:void(0)" class="mr-2 mb-2 text-center">
              <font-awesome-icon icon="window-close"/>
              {{ $t('planes.cancel') }}
            </a>
            <a v-on:click="edit" href="javascript:void(0)" class="text-center mr-2 mb-2"
               v-if="routePlan.temporary ">
              <font-awesome-icon class="m-auto" icon="edit"/>
              {{ $t('routePlan.editElements') }}
            </a>
          </div>
        </div>

        <div class="row align-items-center mt-1 mb-1">
          <div class="col-12 text-center">
            <button disabled class="badge-pill badge-primary p-1 pl-2 pr-2  mr-2 mb-2 text-center">
              <font-awesome-icon icon="plane"/>
              {{ routePlan.planeRegistrationNumber }}
            </button>
            <button disabled class="badge-pill badge-primary p-1 pl-2 pr-2  mr-2 mb-2 text-center">
              <font-awesome-icon icon="clock"/>
              {{ totalFlightTime | formatHour  }}
            </button>
            <button disabled class="badge-pill badge-primary p-1 pl-2 pr-2  mr-2 mb-2 text-center">
              <font-awesome-icon icon="road"/>
              {{ totalDistance | formatNumber }} {{ routePlan.distanceUnit }}
            </button>
            <button disabled class="badge-pill badge-primary p-1 pl-2 pr-2  mr-2 mb-2 text-center">
              <font-awesome-icon icon="gas-pump"/>
              {{ totalConsumption | formatNumber }}{{ routePlan.consumptionUnit }}
            </button>
          </div>
        </div>
        <div class="row" v-if="routePlan.routePlanElements.length>0">
          <div class="col-12 p-0 overflow-table">
            <table class="table table-bordered ">
              <thead>
              <tr>
                <th></th>
                <th>{{ $t('routePlan.trueTrack') }} ({{ magneticCorrectionUnit | unitSuffix }})</th>
                <th>{{ $t('routePlan.magneticTrack') }} ({{ magneticCorrectionUnit | unitSuffix }})</th>
                <th> {{ $t('routePlan.flightTime') }} ({{ $t('routePlan.hour-minute2') }})</th>
                <th> {{ $t('routePlan.distance') }}
                  ({{ routePlan.distanceUnit | unitSuffix }})
                </th>
                <th>{{ $t('routePlan.consumption') }} ( {{ routePlan.consumptionUnit | unitSuffix }})</th>
                <th></th>
              </tr>
              </thead>
              <tr>
                <td colspan="7">
                  {{ $t('routePlan.startTitle') }}: {{ routePlan.startElementTitle }}
                </td>
              </tr>
              <RoutePlanElementCardBody :element="element" :route-plan="routePlan" :index="(index)"
                                        :is-show-settings="false"
                                        v-for="(element, index) in routePlan.routePlanElements"
                                        :key="element.startLatitude"/>

            </table>
          </div>
        </div>
      </div>
      <div class="col-6 ">
        <div class="d-flex map-row p-0">
          <Map ref="map"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Map from "@/components/map/Map";
import RoutePlanElementCardBody from "@/components/routePlan/RoutePlanElementCardBody";
import {HELPER_FUNCTIONS, ROUTE_PLAN_CALCULATOR, TRACK_CEIL} from "@/plugins/utils";

export default {
  name: "RoutePlanElement",
  data() {
    return {
      isEdit: false,
      totalFlightTime: 0,
      totalDistance: 0,
      totalConsumption:0,
      magneticCorrectionUnit: '°',
    }
  },
  components: {RoutePlanElementCardBody, Map},
  props: ['routePlan'],
  emits: ['back', 'edit'],
  mounted() {
    if (this.routePlan.routePlanElements.length > 0) {
      this.setMapCenter();
      this.initMapDrawAndElements();
    }
  },
  methods: {

    back() {
      this.$emit('back', this.routePlan);
    },
    edit() {
      this.$emit('edit', this.routePlan);
    },
    setMapCenter() {
      setTimeout(() => {
        this.$refs.map.setRoutePlanCenter([this.routePlan.routePlanElements[0].startLatitude, this.routePlan.routePlanElements[0].startLongitude], 11)
      }, 500);
    },
    initMapDrawAndElements() {
      this.$refs.map.clearPlanElements();
      this.totalConsumption = ROUTE_PLAN_CALCULATOR.TOTAL_CONSUMPTION(this.routePlan);
      this.routePlan.routePlanElements.forEach((element, index) => {
        this.totalDistance += element.distance;
        this.totalFlightTime += element.flightTime;

        if (index === 0) {
          this.$refs.map.drawMarker(HELPER_FUNCTIONS.getStartLatLong(element), true, {
            title: (index === 0) ? this.routePlan.startElementTitle : element.title,
          });
        }
        this.$refs.map.drawMarker( HELPER_FUNCTIONS.getEndLatLong(element), false, {
          title: element.title,
        });
        this.$refs.map.drawLine([HELPER_FUNCTIONS.getStartLatLong(element), HELPER_FUNCTIONS.getEndLatLong(element)], '#00ffe1', TRACK_CEIL(element.magneticTrack).toString());
      });
    },
  }
}
</script>

<style scoped>
thead {
  position: sticky;
  top: 0px;
  border-bottom: 2px solid #ccc;
}
th {
  padding:10px 5px;
  font-weight:lighter !important;
}
.overflow-table {
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.map-row {
  flex-grow: 1;
  position: relative;
  height: calc(100vh - 80px);
  border-left: 1px solid #6c757d;
  padding: 10px;
}

table td {
  padding: 3px !important;
}

label:first-letter {
  text-transform: capitalize;
}
</style>
