<template>
  <tbody >
  <modal :name="'elementSettings_'+index">
    <div class="m-3">
      <div class="row">
        <div class="col-10 text-left">
          <h4>{{ $t('routePlan.defaultValueSettings') }}</h4>
        </div>
        <div class="col-2 text-right">
          <a v-on:click="hideSettings(index)" href="javascript:void(0)" class="btn btn-warning text-center">
            <font-awesome-icon class="m-auto" icon="times"/>
          </a>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-xl-6 col-lg-12">
          <label>{{ $t('routePlan.elementDefaultSpeed') }}</label>
          <div class="input-group">
            <input type="number" class="form-control" v-model="element.defaultSpeed" v-on:change="$emit('reCalc')"/>
            <div class="input-group-append">
              <div class="input-group-text">{{ routePlan.speedUnit }}</div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12">
          <label>{{ $t('routePlan.elementAvgConsumption') }}</label>
          <div class="input-group">
            <input type="number" class="form-control" v-model="element.avgConsumption" v-on:change="$emit('reCalc')"/>
            <div class="input-group-append">
              <div class="input-group-text">{{ routePlan.avgConsumptionUnit }}</div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-5">
          <a class="mr-3" v-on:click="setToDefault(element)" href="javascript:void(0)">{{
              $t('routePlan.setToDefault')
            }}</a>
        </div>
        <div class="col-12 mt-5 text-right">
          <a v-on:click="hideSettings(index)" href="javascript:void(0)" class="btn btn-secondary text-center">
            <font-awesome-icon class="m-auto" icon="check"/>
            {{ $t('routePlan.unitOk') }}
          </a>
        </div>
      </div>
    </div>
  </modal>
  <modal :name="'elementInfo_'+index">
    <div class="m-3">
      <div class="row">
        <div class="col-10 text-left">
          <h4>{{ $t('routePlan.defaultValueSettings') }}</h4>
        </div>
        <div class="col-2 text-right">
          <a v-on:click="hideInfo(index)" href="javascript:void(0)" class="btn btn-warning text-center">
            <font-awesome-icon class="m-auto" icon="times"/>
          </a>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-xl-6 col-lg-12">
          <label>{{ $t('routePlan.elementDefaultSpeed') }}</label>
          <div class="input-group">
            <input type="number" disabled class="form-control" v-model="element.defaultSpeed"/>
            <div class="input-group-append">
              <div class="input-group-text">{{ routePlan.speedUnit | unitSuffix }}</div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12">
          <label>{{ $t('routePlan.elementAvgConsumption') }}</label>
          <div class="input-group">
            <input type="number" disabled class="form-control" v-model="element.avgConsumption"/>
            <div class="input-group-append">
              <div class="input-group-text">{{ routePlan.avgConsumptionUnit | unitSuffix }}</div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-5 text-right">
          <a v-on:click="hideInfo(index)" href="javascript:void(0)" class="btn btn-secondary text-center">
            <font-awesome-icon class="m-auto" icon="check"/>
            {{ $t('routePlan.back') }}
          </a>
        </div>
      </div>
    </div>
  </modal>

  <tr :style="'background:'+bgColor">
    <td>{{ (bgColor?index:(index + 1)) }}. {{ element.title }}</td>
    <td>
      {{ element.trueTrack | formatTrack }}
    </td>
    <td>
      {{ element.magneticTrack | formatTrack }}
    </td>
    <td>
      {{ element.flightTime | formatHourWithoutUnit }}
    </td>
    <td>
      {{ element.distance | formatNumber }}
    </td>
    <td>
      {{ element.consumption | formatNumber }}
    </td>
    <td class="text-right">
      <a href="javascript:void(0)" v-if="isShowSettings" v-on:click="showSettings(index)">
        <font-awesome-icon icon="cog"/>
      </a>
      <a href="javascript:void(0)" v-if="isShowSettings" v-on:click="$emit('deleteI', index)" class="ml-1">
        <font-awesome-icon icon="trash"/>
      </a>
      <a href="javascript:void(0)" v-if="!isShowSettings" v-on:click="showInfo(index)">
        <font-awesome-icon icon="info-circle"/>
      </a>

    </td>
  </tr>
  </tbody>
</template>

<script>
export default {
  name: "RoutePlanElementCardBody",
  props: ['element', 'routePlan', 'index', 'isShowSettings', 'bgColor'],
  emits: ['reCalc', 'deleteI'],
  data() {
    return {
			/** @type {AngleUnitType} */
      magneticCorrectionUnit: this.$units.angle.deg.key,
    }

  },
  mounted() {
  },
  methods: {
    showSettings(index) {
      this.$modal.show('elementSettings_' + index)
    },
    showInfo(index) {
      this.$modal.show('elementInfo_' + index)
    },
    hideSettings(index) {
      this.$modal.hide('elementSettings_' + index)
    },
    hideInfo(index) {
      this.$modal.hide('elementInfo_' + index)
    },
    setToDefault(element) {
      element.defaultSpeed = Number(this.routePlan.defaultSpeed);
      element.avgConsumption = this.routePlan.avgConsumption;
      this.$emit('reCalc');
    },
  }
}
</script>

<style scoped>
table td {
  padding: 3px !important;
}
</style>
